<script lang="ts" setup>
import { computed } from 'vue'

const props = defineProps<{
  size?: "xl" | "md"
}>()

const maxWidthClass = computed(() => {
  switch (props.size) {
    case 'md':
      return 'max-w-[520px]'
    case 'xl':
      return 'max-w-4xl'
  }

  return 'max-w-[500px]'
})
</script>
<template>
  <div class="w-full mt-6 px-6 py-4" :class="maxWidthClass">
    <slot />
  </div>
</template>
